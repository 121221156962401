import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/el';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/el';
import { ConfigProvider } from 'antd';
import el_GR from 'antd/es/locale/el_GR';

dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.locale('el');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
moment().locale('el');

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? "/";
root.render(
  <BrowserRouter basename={baseUrl}>
    <ConfigProvider
      locale={el_GR}
      theme={{
        token: {
          fontSize: 15
        },
        components: {
          Checkbox: {
            lineWidth: 2,
            colorBgContainer: "#ececec"
          }
        }
      }}
    >
      <App />
    </ConfigProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
