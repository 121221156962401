import { CloudDownloadOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, message, Button, Card, Col, List, Space, Switch, Typography, Badge, Modal, Row, Descriptions, Grid, Table, Statistic } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../App';
import { AddClinicalTrialPopup, ClinicalTrialPopup } from '../components/ClinicalTrialPopup';
import { PatientAdmissionsTable } from '../components/PatientAdmission';
import { PatientsTable } from '../components/Patients';
import { Ecrf, Patient, PatientAdmission, UserInfo } from '../models/models';
import { deletePatient, getECRFS, getPatientAdmission, getPatients, postPatient, postPatientAdmission } from '../models/serverApi';
import { PageHeader } from '@ant-design/pro-layout';
import EcrfList from '../components/EcrfList';

const Dashboard: React.FC = props => {
    const [context, setContext] = useContext(AppContext);
    const [ready, setReady] = useState(false);
    const [users, setUsers] = useState<Array<UserInfo>>([]);
    const [patientAdmissions, setPatientAdmissions] = useState<Array<PatientAdmission>>([]);
    const [patients, setPatients] = useState<Array<Patient>>([]);

    const navigate = useNavigate();
    const screens = Grid.useBreakpoint();

    useEffect(() => {
        if (ready) {
            return;
        }

        getPatientAdmission().then((pa) => {
            setPatientAdmissions(pa);
            setReady(true);
        });

        getPatients().then((pa) => {
            setPatients(pa);
            setReady(true);
        });

        setContext(prevContext => {
            return {
                ...prevContext,
                selection: {
                    clinicalTrial: prevContext.selection?.clinicalTrial,
                    patient: undefined,
                    admission: undefined,
                }
            };
        });
    }, [ready]);

    const isAdmin = () => {
        return context.user?.admin;
    }

    const patientAdmissionFinish = async (patientAdmission: PatientAdmission) => {
        setReady(false);
    }

    const patientAdmissionDeleteFinish = (patientAdmission: PatientAdmission) => {
        setReady(false);
    }

    const patientsFinish = async (patient?: Patient) => {
        setReady(false);
    }

    const getFilesSource = () => {
        if (!context.selection?.clinicalTrial) {
            return [];
        }

        return [{
            name: "Πρωτόκολλο μελέτης",
            file: context.selection?.clinicalTrial.protocolFile,
        },
        {
            name: "Φόρμα συγκατάθεσης ασθενούς",
            file: context.selection?.clinicalTrial.patientConsentFile
        }];
    }

    return (<>
        <Badge.Ribbon text={context.user?.admin ? "Admin" : context.user?.professor ? "Professor" : "User"}>
            <PageHeader
                className="site-page-header-responsive"
            // title={`Καλωσήρθες, ${context[0]?.user?.firstName} ${context[0]?.user?.lastName} (${context[0].user?.email})`}
            >
            </PageHeader>
            <Content>
                {/* {isAdmin() &&
                    <AddClinicalTrialPopup></AddClinicalTrialPopup>
                } */}
                <Row>
                    <Col span={24}>
                        {!isAdmin &&
                            <Card title={`${context?.selection?.clinicalTrial?.title}`}
                                bodyStyle={{ padding: "0" }} bordered hoverable className="always" style={{ marginTop: "24px" }}>
                            </Card>
                        }
                    </Col>
                    <Col span={24}>
                        <Card title="Υλικό μελέτης" bordered hoverable className="always" style={{ marginTop: "24px" }}>
                            <List
                                dataSource={getFilesSource()}
                                renderItem={(item) => (
                                    <List.Item actions={
                                        [<Button type="primary" onClick={() => {
                                            window.open(`/Uploads/${item.file}`, "_blank");
                                        }}>
                                            <CloudDownloadOutlined /></Button>]
                                    }>{item.name}
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Col>
                    {/* <Col span={24}>
                        <Card title="Φόρμες" bordered hoverable className="always" style={{ marginTop: "24px" }}>
                            <EcrfList ecrfList={context.ecrfs}></EcrfList>
                        </Card>
                    </Col> */}
                    <Col span={24}>
                        <PatientsTable patients={patients} patientAdmissions={patientAdmissions} afterEdit={patientsFinish} afterDelete={patientsFinish} ></PatientsTable>
                    </Col>
                    {/* <Col span={24}>
                        <PatientAdmissionsTable patients={patients} patientAdmissions={patientAdmission} onEdit={patientAdmissionFinish} onDelete={patientAdmissionDeleteFinish} ></PatientAdmissionsTable>
                    </Col> */}
                </Row>
                <Card bordered hoverable className="always" style={{ marginTop: "50px" }}>
                    &copy; 2023 - Ερευνητικό Κέντρο Αθηροθρόμβωσης - Seaside - Powered by <a href="https://biostats.gr/e-crf-edc/">biostats.gr</a>
                </Card>
            </Content>
        </Badge.Ribbon>
    </>);
}

export default Dashboard;
