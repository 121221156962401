import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, DatePicker, Table, Space, Row, Select, Card } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, ExclamationCircleOutlined, SelectOutlined } from '@ant-design/icons';
import { Patient, PatientAdmission } from '../models/models';
import moment from 'moment';
import { deletePatientAdmission, postPatientAdmission } from '../models/serverApi';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/el_GR';

export const PatientAdmissionPopup: React.FC<{ patients: Patient[], visible: boolean, onCancel: () => void, onFinish: (patientAdmission: PatientAdmission) => void, patientAdmission?: PatientAdmission }> = ({ patients, visible, onCancel, onFinish, patientAdmission }) => {
    const [form] = Form.useForm<PatientAdmission>();

    useEffect(() => {
        if (patientAdmission) {
            form.setFieldsValue({ ...patientAdmission, date: dayjs(patientAdmission.date) });
        }
        else {
            ;
            form.setFieldsValue({ patientId: undefined, date: undefined, diagnosis: "", notes: "" });
        }
    }, [patientAdmission, visible]);

    return (
        <Modal
            title={patientAdmission ? 'Επεξεργασία εισαγωγής' : 'Νέα εισαγωγή'}
            open={visible}
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        onFinish({ ...values, id: patientAdmission?.id });
                    })
                    .catch(info => {
                        console.log('Τα δεδομένα δεν είναι έγκυρα', info);
                    });
            }}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item name="patientId" label="Ασθενής" rules={[{ required: true, message: 'Επιλέξτε έναν ασθενή' }]}>
                    <Select options={patients.map(p => { return { value: p.id, label: p.patientName } })}>
                    </Select>
                </Form.Item>
                <Input.Group compact>
                    <Form.Item style={{ width: "50%" }} name="date" label="Ημ/νια εισαγωγής" rules={[{ required: true, message: 'Επιλέξτε μια ημερομηνία' }]}>
                        <DatePicker locale={locale} format={"LL"} style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item style={{ width: "50%" }} name="DischargeDate" label="Ημ/νια εξιτηρίου" >
                        <DatePicker locale={locale} format={"LL"} style={{ width: "100%" }} />
                    </Form.Item>
                </Input.Group>
                <Form.Item name="diagnosis" label="Διάγνωση">
                    <Input />
                </Form.Item>
                <Form.Item name="notes" label="Σημειώσεις">
                    <Input.TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export const PatientAdmissionsTable: React.FC<{ patients: Patient[], patientAdmissions: PatientAdmission[], onEdit: (patientAdmission: PatientAdmission) => void, onDelete: (patientAdmission: PatientAdmission) => void, selectionButton?: boolean, selectPatientAdmission?: (patientAdmission?: PatientAdmission) => void }> =
    ({ patients, patientAdmissions, onEdit, onDelete, selectionButton, selectPatientAdmission }) => {
        const [formVisible, setFormVisible] = useState(false);
        const [currentPatientAdmission, setCurrentPatientAdmission] = useState<PatientAdmission | undefined>(undefined);
        const [patientAdmissionsFilter, setPatientAdmissionsFilter] = useState("");

        const showForm = (patientAdmission: PatientAdmission | undefined) => {
            setCurrentPatientAdmission(patientAdmission);
            setFormVisible(true);
        };

        const columns = [
            {
                title: 'Ημ/νια εισαγωγής',
                dataIndex: 'date',
                key: 'date',
                render: (values: PatientAdmission, record: PatientAdmission, index: number) => moment(record.date).format("LL")
            },
            {
                title: 'Ασθενής',
                key: 'patient',
                render: (values: PatientAdmission, record: PatientAdmission, index: number) => record.patient.patientName
            },
            {
                title: 'Ημ/νια εξιτηρίου',
                dataIndex: 'DischargeDate',
                key: 'DischargeDate',
            },
            {
                title: 'Διάγνωση',
                dataIndex: 'diagnosis',
                key: 'Diagnosis',
            },
            {
                title: 'Σημειώσεις',
                dataIndex: 'notes',
                key: 'treatment',
            },
            {
                title: '',
                key: 'action',
                render: (text: any, record: PatientAdmission) => (
                    <Space.Compact>
                        {selectionButton && <Button type="primary" icon={<SelectOutlined />} onClick={() => selectPatientAdmission?.(record)}>
                            Επιλογή
                        </Button>}
                        <Button type="primary" icon={<EditOutlined />} onClick={() => showForm(record)}>
                            Επεξεργασία
                        </Button>
                        <Button danger icon={<DeleteOutlined />} onClick={() => {
                            Modal.confirm({
                                title: 'Διαγραφή εισαγωγής',
                                icon: <ExclamationCircleOutlined />,
                                content: `Είστε σίγουρος οτι θέλετε να διαγράψετε την εισαγωγή;`,
                                okText: 'Ναι',
                                cancelText: 'Όχι',
                                onOk: () => { onPatientAdmissionDelete(record) },
                                onCancel: () => { },
                            });
                        }}>
                            Διαγραφή
                        </Button>
                    </Space.Compact>
                ),
            },
        ];

        const onPatientAdmissionDelete = async (patientAdmission: PatientAdmission) => {
            await deletePatientAdmission(patientAdmission?.id ?? 0);
            onDelete?.(patientAdmission);
        }

        const onPatientAdmissionEdit = async (patientAdmission: PatientAdmission) => {
            await postPatientAdmission(patientAdmission);
            onEdit?.(patientAdmission);
        }

        return (
            <Card title="Εισαγωγές" bordered hoverable className="always" style={{ marginTop: "24px" }}
                extra={<Button type="primary" onClick={() => { showForm(undefined); }}><PlusOutlined /> Προσθήκη εισαγωγής</Button>}>
                <Table columns={columns} dataSource={patientAdmissions.filter(u => u.patient.patientName.toLowerCase().includes(patientAdmissionsFilter) || u.patient?.patientDescription?.toLowerCase().includes(patientAdmissionsFilter))} rowKey="Id"
                    pagination={{ position: ["topRight"], responsive: true, defaultPageSize: 20, total: patientAdmissions.length, showSizeChanger: true, showQuickJumper: true, showTotal: (total: number) => <Space direction="horizontal" size={5}><Input.Search value={patientAdmissionsFilter} onChange={(e) => { setPatientAdmissionsFilter(e.target.value) }} placeholder="Αναζήτηση" enterButton style={{}} /> <span>{total} Εισαγωγές</span></Space> }}
                />
                <PatientAdmissionPopup patients={patients} visible={formVisible} onCancel={() => setFormVisible(false)} onFinish={(values: any) => { setFormVisible(false); onPatientAdmissionEdit(values); }} patientAdmission={currentPatientAdmission} />
            </Card>
        );
    };

