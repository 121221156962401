import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu, Col, Row, Card } from 'antd';
import { Link } from 'react-router-dom';
import { AimOutlined, BookOutlined, BuildOutlined } from '@ant-design/icons';
import { AppContext } from '../App';
import { TopNavHeader } from '@ant-design/pro-layout';

const { Header, Content, Footer, Sider } = Layout;
const DashboardLayout: React.FC<{ children: React.ReactNode; }> = props => {
    const children = props.children ? props.children : [];
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const context = useContext(AppContext);


    return <Layout className="dashboard-layout">
        <Header>
            <Row>
                <Col span={24}>
                    <Link className="logo" to="/"><img src="/logo_el1_export.png" style={{ maxHeight: "55px" }} /></Link>
                    <Menu theme="light" style={{ display: "flex", justifyContent: "flex-end" }} mode="horizontal" items={
                        [
                            {
                                key: "home",
                                label: <Link to="/dashboard">Αρχική</Link>
                            },
                            {
                                key: "logout",
                                label: <a href="/Identity/Account/Logout">Αποσύνδεση</a>
                            }, {
                                key: "user",
                                label: context[0]?.user?.email
                            }]
                    }>
                    </Menu>
                </Col>
            </Row>
        </Header>
        <Content>
            <Layout>
                <Content>
                    <div className="dashboard-layout-content">{children}</div>
                </Content>
            </Layout>
        </Content>
    </Layout>;
}

export default DashboardLayout;