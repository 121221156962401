import { FormInstance } from "antd";
import { EcrfSection, FormData } from "./models/models";

export const bmiCalculation = (section: EcrfSection, antdForm: FormInstance<any>) => {
    let weightField = section.form?.formFields.filter(ff => ff.label == "Βάρος")?.[0];
    let heightField = section.form?.formFields.filter(ff => ff.label == "Υψος")?.[0];

    if (!weightField || !heightField) {
        return;
    }

    let weightValue = antdForm.getFieldValue(`field-${weightField.id}`);
    let heightValue = antdForm.getFieldValue(`field-${heightField.id}`);
    try {
        return weightValue / Math.pow(heightValue / 100.0, 2);
    }
    catch {
        return null;
    }
}

export const egfrCalculation = (section: EcrfSection, antdForm: FormInstance<any>, allFormValues: FormData[] | null) => {
    try {
        let creField = section.form?.formFields.filter(ff => ff.label == "Cre")?.[0];
        // age 12
        // sex 14
        // race 11
        if (!creField) {
            return null;
        }

        let creValue = antdForm.getFieldValue(`field-${creField.id}`);
        let dhmografikaForm = allFormValues?.filter(afv => afv.formId == 3)?.[0];
        if (!dhmografikaForm) {
            return null;
        }

        let ageFieldValue = dhmografikaForm.formFieldValues.filter(ffv => ffv.fieldId == 12)?.[0];
        let sexFieldValue = dhmografikaForm.formFieldValues.filter(ffv => ffv.fieldId == 14)?.[0];
        let raceFieldValue = dhmografikaForm.formFieldValues.filter(ffv => ffv.fieldId == 11)?.[0];

        let age = parseFloat(ageFieldValue?.formValue);
        let isMan = sexFieldValue?.formValue == "Άνδρας";
        let isBlack = raceFieldValue?.formValue?.trim() == "Μαύρη";
        let k = isMan ? 0.9 : 0.7;
        let a = isMan ? -0.411 : -0.329;

        let egfr = 141
            * Math.pow(Math.min(creValue / k, 1), a)
            * Math.pow(Math.max(creValue / k, 1), -1.209)
            * Math.pow(0.9929, age)
            * (!isMan ? 1.018 : 1)
            * (isBlack ? 1.159 : 1);

        return egfr;
    }
    catch {
        return null;
    }
}