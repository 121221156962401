import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Navigate, Route, RouterProps, BrowserRouter, Routes, useLocation } from "react-router-dom";
import "./App.css";
import ExternalRedirect from "./pages/External";
import Dashboard from "./pages/Dashboard";
import DashboardLayout from "./pages/DashboardLayout";
import { ClinicalTrial, Ecrf, Patient, PatientAdmission, UserInfo } from "./models/models";
import { getECRFS } from "./models/serverApi";
import { EcrfPage } from "./pages/EcrfPage";

const InitialAppContext = {
  isLoggedIn: false as boolean,
  user: null as UserInfo | null,
  reloadPage: false as boolean,
  ecrfs: [] as Array<Ecrf>,
  selection: null as { patient: Patient | undefined, clinicalTrial: ClinicalTrial | undefined, admission: PatientAdmission | undefined } | null
};

export const AppContext = React.createContext<[typeof InitialAppContext, Dispatch<SetStateAction<typeof InitialAppContext>>]>([InitialAppContext, () => { }]);

function App() {
  const [context, setContext] = useState<typeof InitialAppContext>(InitialAppContext);
  const location = useLocation();
  const [ready, setReady] = useState<Boolean>(false);

  useEffect(() => {
    (async () => {
      const response = await fetch("/api/User", {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });


      if (response.status === 200) {
        const info: UserInfo = await response.json();
        setContext((p) => {
          return { ...p, isLoggedIn: true, user: info, reloadPage: false };
        });
        if (context.ecrfs.length == 0) {
          getECRFS().then(ec => {
            setContext((p) => {
              return { ...p, ecrfs: ec, selection: { patient: p?.selection?.patient, admission: p?.selection?.admission, clinicalTrial: info.trials?.[0] } };
            });
          });
        }

      } else {
        setContext((p) => {
          return { ...p, isLoggedIn: false, user: null, reloadPage: false };
        });
      }

      setReady(true);
    })();

  }, [location.pathname, context.reloadPage]);

  return (
    <AppContext.Provider value={[context, setContext]}>
      <div className="App">
        {!ready ? (
          <div></div>
        ) :
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            {/* <Route path="/welcome" render={() => { return context.isLoggedIn ? <Redirect to="/dashboard" /> : <LandingLayout><Welcome /></LandingLayout>; }} /> */}
            <Route path="/dashboard" element={
              context.isLoggedIn ? <DashboardLayout><Dashboard /></DashboardLayout> : <Navigate to="/external" replace state={{ url: "/Identity/Account/Login?returnUrl=" + encodeURIComponent(location.pathname + location.search) }} />}
            />
            <Route path="/external" element={<ExternalRedirect />} />
            <Route path="/ecrf/:id" element={<DashboardLayout><EcrfPage></EcrfPage></DashboardLayout>} />
          </Routes>
        }
      </div>
    </AppContext.Provider >
  );
}

export default App;
