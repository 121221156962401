import { Form, Modal, Spin } from "antd";
import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import { PatientsTable } from "./Patients";
import { Patient, PatientAdmission } from "../models/models";
import { getPatientAdmission, getPatients } from "../models/serverApi";

export const SelectPatient: React.FC<{ open: boolean, onCancel: () => void, onOK: () => void }> = ({ open, onCancel, onOK }) => {
    const [form] = Form.useForm();
    const [ready1, setReady1] = useState(false);
    const [context, setContext] = useContext(AppContext);
    const [patients, setPatients] = useState<Array<Patient>>([]);
    const [patientAdmissions, setPatientAdmissions] = useState<Array<PatientAdmission>>([]);

    useEffect(() => {
        if (!open) {
            setReady1(false);
            form.resetFields();
            return;
        }

        getPatients().then((pa) => {
            setPatients(pa);
            setReady1(true);
        });

        getPatientAdmission().then((pa) => {
            setPatientAdmissions(pa);
            setReady1(true);
        });

    }, [open]);

    const afterEdit = (patient?: Patient) => {
        getPatients().then((pa) => {
            setPatients(pa);
            setReady1(true);
        });
    }

    const selectPatient = (patient?: Patient) => {
        setContext(prevContext => {
            let pa = patientAdmissions?.find(pa => pa.patientId == patient?.id);
            return {
                ...prevContext,
                selection: {
                    ...prevContext.selection,
                    patient: patient,
                    admission: pa
                } as any
            };
        });

        onOK();
    }

    return (
        <Modal open={open}
            width={"100%"}
            title="Επιλογή ασθενή"
            cancelText="Ακύρωση"
            onCancel={() => {

                onCancel();
            }}
            onOk={() => {
                onOK();
            }}
        >
            {!ready1 && <Spin key={"spin"} size="small" />}
            {ready1 &&
                <PatientsTable key={"patients-table"} patients={patients} patientAdmissions={patientAdmissions} selectPatient={selectPatient} selectionButton afterDelete={afterEdit} afterEdit={afterEdit}></PatientsTable>
            }
        </Modal >
    );
};