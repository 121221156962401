import { Form, Modal, Spin } from "antd";
import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import { PatientsTable } from "./Patients";
import { Patient, PatientAdmission } from "../models/models";
import { getPatientAdmission, getPatients } from "../models/serverApi";
import { PatientAdmissionsTable } from "./PatientAdmission";

export const SelectAdmission: React.FC<{ open: boolean, onCancel: () => void, onOK: () => void }> = ({ open, onCancel, onOK }) => {
    const [form] = Form.useForm();
    const [ready1, setReady1] = useState(false);
    const [context, setContext] = useContext(AppContext);
    const [patientAdmissions, setPatientAdmissions] = useState<Array<PatientAdmission>>([]);
    const [patients, setPatients] = useState<Array<Patient>>([]);

    useEffect(() => {
        if (!open) {
            setReady1(false);
            form.resetFields();
            return;
        }

        getPatientAdmission().then((pa) => {
            setPatientAdmissions(pa);
            getPatients().then((pa) => {
                setPatients(pa);
                setReady1(true);
            });
        });
    }, [open]);

    const afterEdit = (patientAdmission?: PatientAdmission) => {
        getPatientAdmission().then((pa) => {
            setPatientAdmissions(pa);
            getPatients().then((pa) => {
                setPatients(pa);
                setReady1(true);
            });
        });
    }

    const selectPatientAdmission = (patientAdmission?: PatientAdmission) => {
        setContext(prevContext => {
            return {
                ...prevContext,
                selection: {
                    ...prevContext.selection,
                    admission: patientAdmission
                } as any
            };
        });

        onOK();
    }

    const getPatientAdmissions = (selectedPatientId?: number) => {
        if (!selectedPatientId) {
            return patientAdmissions;
        }

        return patientAdmissions.filter(pa => pa.patientId == selectedPatientId);
    }

    return (
        <Modal open={open}
            width={"100%"}
            title="Επιλογή εισαγωγής"
            cancelText="Ακύρωση"
            onCancel={() => {

                onCancel();
            }}
            onOk={() => {
                onOK();
            }}
        >
            {!ready1 && <Spin size="small" />}
            {ready1 &&
                <PatientAdmissionsTable patients={patients} patientAdmissions={getPatientAdmissions(context?.selection?.patient?.id)}
                    selectionButton selectPatientAdmission={selectPatientAdmission} onDelete={afterEdit} onEdit={afterEdit}></PatientAdmissionsTable>
            }
        </Modal >
    );
};