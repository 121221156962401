export interface UserInfo {
    id: string,
    email: string,
    student: boolean,
    professor: boolean,
    admin: boolean,
    supervisor: boolean,
    firstName: string,
    lastName: string,
    enrolledCoursesCount: number
    roles: Array<string>,
    studentIdentityNumber: string,
    studentNumber: string,
    emailConfirmed: boolean,
    registrationDate: Date,
    birthDate: Date,
    city: string,
    fatherName: string,
    identityAuthority: string,
    identityNumber: string,
    mobilePhone: string,
    motherName: string,
    placeBirth: string,
    postalCode: string,
    semester: number
    street: string,
    telephone: string,
    trials: Array<ClinicalTrial>
}

export interface StudentInfo {
    id: string,
    name: string,
    email: string,
    motherName: string,
    fatherName: string,
    identityAuthority: string,
    identityNumber: string,
    birthDate: string,
    city: string,
    mobilePhone: string,
    placeBirth: string,
    postalCode: string,
    street: string,
    studentIdentityNumber: string,
    studentNumber: string,
    telephone: string,
}

export interface Patient {
    id: number,
    patientName: string,
    patientDescription?: string,
    clinicalTrialId: number,
    clinicalTrial: ClinicalTrial,
    patientClass: string
}

export interface ClinicalTrial {
    id: number,
    title: string,
    description: string,
    protocolFile: string,
    patientConsentFile: string,
    inclusionCriteriaJson: string,
    exclusionCriteriaJson: string,
}

export interface PatientAdmission {
    id: number | undefined;
    patientId?: number;
    date: Date;
    patient: Patient;
    diagnosis: string;
    notes: string;
}

export interface Ecrf {
    id: number;
    clinicalTrialId: number;
    trial: ClinicalTrial;
    name: string | null;
    sections: EcrfSection[];
}

export interface EcrfSection {
    id: number;
    name: string;
    ecrfId: number;
    ecrf: Ecrf;
    parentId: number | null;
    children: EcrfSection[];
    formId: number | null;
    form: EcrfForm | null;
    extraJson: string;
}

export interface EcrfSectionExtraProperties {
    isAdminOnly: boolean;
}

export interface EcrfForm {
    id: number;
    clinicalTrialId: number;
    name: string;
    description: string;
    version: number;
    trial: ClinicalTrial;
    formFields: FormField[];
    formData: FormData[];
    section: EcrfSection | null;
}

export interface FormField {
    formId: number;
    id: number;
    form: EcrfForm;
    fieldType: FieldTypeEnum;
    label: string;
    required: boolean;
    tooltip: string | null;
    listValues: string | null;
    allowOther: boolean;
    min: number | null;
    max: number | null;
    minAlert: number | null;
    maxAlert: number | null;
    units: string;
    interval: number | null;
    displayOrder: number;
    extraStyle: string | null;
}

export interface FormData {
    formId: number;
    sectionId: number;
    id: number;
    dateSubmitted: string;
    userId: string | null;
    // user: ApplicationUser | null;
    form: EcrfForm;
    formFieldValues: FormFieldValue[];
}

export interface FormFieldValue {
    formId: number;
    formDataId: number;
    fieldId: number;
    patientAdmissionId: number;
    formValue: string;
    isOtherValue: boolean;
    formData: FormData;
    field: FormField;
    form: EcrfForm;
    patientAdmission: PatientAdmission;
}

export enum FieldTypeEnum {
    Text,
    Label,
    ListBox,
    CheckBox,
    RadioButton,
    Integer,
    Double,
    DateTime,
    Percentage,
    Seperator,
}
export interface FormFieldValueVM {
    fieldId: number,
    formValue: string,
    isOtherValue: boolean,
}

export interface SaveFormDataVM {
    formId: number,
    sectionId: number,
    patientAdmissionId: number,
    patientId: number,
    formFieldValues: Array<FormFieldValueVM>
}

export interface InclusionCriteria {
    inclusion: Array<string>
}

export interface ExclusionCriteria {
    exclusion: Array<string>
}