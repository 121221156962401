import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../App";
import { Ecrf, EcrfSectionExtraProperties, Patient, FormData, EcrfSection } from "../models/models";
import { Row, Col, Anchor, List, Menu, Tabs, Button, Card, Typography, Descriptions, Space, Modal } from "antd";
import { red, grey, blue, yellow, green } from '@ant-design/colors';
import { FormView } from "../components/FormView";
import { PageHeader } from "@ant-design/pro-layout";
import TabPane from "antd/es/tabs/TabPane";
import { Content } from "antd/es/layout/layout";
import moment from "moment";
import { SelectPatient } from "../components/SelectPatientModal";
import { getFormValues, getPatients } from "../models/serverApi";
import { SelectAdmission } from "../components/SelectAdmissionModal";
import { CheckCircleFilled, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Prompt } from "../helpers";

export const EcrfPage: React.FC = () => {
    const [context, setContext] = useContext(AppContext);
    const [ecrf, setEcrf] = useState<Ecrf>();
    const [selectedSection, setSelectedSection] = useState(-1);
    const [selectPatientModalOpen, setSelectPatientModalOpen] = useState(false);
    const [selectPatientAdmissionModalOpen, setSelectPatientAdmissionModalOpen] = useState(false);
    const [patients, setPatients] = useState<Array<Patient>>([]);
    const [formValues, setFormValues] = useState<FormData | null>();
    const [allFormValues, setAllFormValues] = useState<Array<FormData> | null>(null);
    let { id } = useParams<{ id: string | undefined }>();
    const [refresh, setRefresh] = useState<boolean>(false);
    const { confirm } = Modal;

    useEffect(() => {

        if (id == null || id == undefined) {
            return;
        }

        var nid = parseInt(id);

        var e = context.ecrfs.filter(e => e.id == nid)?.[0];
        if (!e) {
            return;
        }

        setEcrf(e);
        setSelectedSection(e?.sections?.[0].id ?? -1);
        setContext({ ...context, selection: { patient: context.selection?.patient, admission: context.selection?.admission, clinicalTrial: e.trial } });

    }, [id, context.ecrfs]);

    useEffect(() => {
        if (!ecrf?.id || !context.selection?.patient || !context.selection?.admission) {
            return;
        }

        fetchAllData();
    }, [ecrf?.id, selectedSection, context.selection?.patient, context.selection?.admission]);

    useEffect(() => {
        if (!refresh) {
            return;
        }

        setAllFormValues(null);
        fetchAllData();
        setRefresh(false);
    }, [refresh]);

    const fetchAllData = () => {
        if (!ecrf?.id || !context.selection?.patient || !context.selection?.admission) {
            return;
        }

        let ss = getSelectedSection();
        if (!ss || !ss.formId) {
            return;
        }
        getFormValues(ss?.formId, ss?.id, context?.selection?.admission?.id ?? 0).then((fv) => {
            setFormValues(fv);
        });

        if (allFormValues != null && refresh != true) {
            return;
        }

        let allSections = [...ecrf.sections];

        for (let i = 0; i < allSections.length; i++) {
            let s = allSections[i];
            if (!s || s.formId == null) {
                if ((s?.children?.length ?? 0) > 0) {
                    allSections.push(...s.children);
                }
                continue;
            }

            getFormValues(s.formId, s?.id, context?.selection?.admission?.id ?? 0).then((fv) => {
                if (!fv) {
                    return;
                }

                setAllFormValues(p => {
                    if (!p) {
                        return [fv];
                    }

                    return [...p, fv];
                });
            });
        }
    }
    const calculateSectionState = (section: EcrfSection) => {
        if (!section || section.formId == null || section?.children?.length > 0) {
            return;
        }

        let formV = formValues?.sectionId == section.id ?
            formValues :
            allFormValues?.find(afv => afv.formId == section.formId && afv.sectionId == section.id);

        if (!formV || !section || !section?.form) {
            return <CloseCircleOutlined style={{ color: red[6] }} />;
        }

        let hasAny = false;
        let hasAll = true;
        for (let i = 0; i < section.form.formFields.length; i++) {
            let fv = section.form.formFields[i];

            let value = formV.formFieldValues.find(ffv => ffv.fieldId == fv.id);
            if (value == null) {
                hasAll = false;
                break;
            }

            hasAny = true;
        }



        if (hasAll) {
            return <CheckCircleOutlined style={{ color: green[6] }} />;
        }

        if (hasAny) {
            return <CheckCircleOutlined style={{ color: grey[6] }} />;
        }

        return <CloseCircleOutlined style={{ color: red[6] }} />;
    }

    const getSectionItems = () => {
        let isAdmin = context?.user?.admin === true;
        return ecrf?.sections.map(s => {
            let extraProperties: EcrfSectionExtraProperties | null = null;
            try {
                extraProperties = s.extraJson ? JSON.parse(s.extraJson) : null;
            }
            catch {
            }

            const isDisabled = () => {
                return (extraProperties?.isAdminOnly === true && !isAdmin);
            }

            return {
                key: s.id,
                label: s.children?.length > 0 ? <span style={{ fontStyle: "italic" }}>{s.name}</span> : s.name,
                type: 'item',
                icon: calculateSectionState(s),
                disabled: isDisabled() ? true : false,
                children: s.children && s.children.length > 0 ?
                    s.children.map(ss => {
                        return {
                            key: ss.id,
                            label: ss.name,
                            type: 'item',
                            icon: calculateSectionState(ss),
                        }
                    }) : null
            };
        });
    }

    const linkClick = (e: any, link: { title: React.ReactNode, href: string }) => {
        setSelectedSection(parseInt(link.href));
    }

    const getSelectedSection = () => {
        for (let i = 0; i < (ecrf?.sections?.length ?? 0); i++) {
            if (ecrf?.sections[i].id == selectedSection) {
                return ecrf?.sections[i];
            }

            for (let j = 0; j < (ecrf?.sections[i]?.children?.length ?? 0); j++) {
                if (ecrf?.sections[i].children[j].id == selectedSection) {
                    return ecrf?.sections[i].children[j];
                }
            }
        }
    }

    const selectPatient = () => {
        setSelectPatientModalOpen(true);
    }

    const selectPatientAdmission = () => {
        setSelectPatientAdmissionModalOpen(true);
    }

    const cancelselectPatient = () => {
        setSelectPatientModalOpen(false);
    }
    const okSelectPatient = () => {
        setSelectPatientModalOpen(false);
        setSelectedSection(ecrf?.sections?.[0].id ?? -1);
    }

    const cancelSelectPatientAdmission = () => {
        setSelectPatientAdmissionModalOpen(false);
    }
    const okSelectPatientAdmission = () => {
        setSelectPatientAdmissionModalOpen(false);
    }

    return (
        <Card>
            <PageHeader
                className="site-page-header-responsive"
                onBack={() => {
                    confirm({
                        icon: <ExclamationCircleOutlined />,
                        okText: "Ναι",
                        cancelText: "Όχι",
                        content: "Σίγουρα θέλετε να φύγετε απο την σελίδα;",
                        onOk() {
                            window.history.back();
                        },
                        onCancel() {
                        },
                    });
                }}
                title={context.selection?.patient?.clinicalTrial?.title ?? "Κλινική δοκιμή"}
                subTitle=""
            // footer={
            //     <Tabs defaultActiveKey="1">
            //         <TabPane tab="Details" key="1" />
            //         <TabPane tab="Rule" key="2" />
            //     </Tabs>
            // }
            >
                <Content>
                    <Card title="Ασθενής"
                        extra={[<Button danger onClick={selectPatient}>Επιλογή άλλου ασθενή</Button>]}>
                        <Row>
                            <Descriptions>
                                <Descriptions.Item label="Κωδικός ασθενή">
                                    {context.selection?.patient?.patientName ?? "Επιλέξτε ασθενή"}
                                </Descriptions.Item>
                                <Descriptions.Item label="Περιγραφή">
                                    {context.selection?.patient?.patientDescription ?? "Επιλέξτε ασθενή"}
                                </Descriptions.Item>
                                <Descriptions.Item label="Κατηγορία">
                                    {context.selection?.patient?.patientClass ?? "Επιλέξτε ασθενή"}
                                </Descriptions.Item>
                            </Descriptions>
                        </Row>
                        {/* <Row>
                            <Space>
                                {<Typography.Text>Εισαγωγή: </Typography.Text>}
                                {context.selection?.admission?.date ? moment(context.selection?.admission?.date).format("LLLL") : "Επιλέξτε εισαγωγή"}
                                {<Button onClick={selectPatientAdmission} disabled={!context.selection?.patient?.id}>Επιλογή</Button>}
                            </Space>
                        </Row> */}
                    </Card>

                    <Row>
                        <Col span={8}>
                            <Card title="Κατηγορίες">
                                <Menu mode="inline" forceSubMenuRender={true} expandIcon={<></>}
                                    openKeys={ecrf?.sections.map(s => s.id.toString())} items={getSectionItems()}
                                    selectedKeys={[selectedSection.toString()]}
                                    onSelect={(info: any) => { setSelectedSection(info.key); }}>
                                </Menu>
                            </Card>
                        </Col>
                        <Col span={16}>
                            <FormView section={getSelectedSection()} formValues={formValues}
                                allFormValues={allFormValues}
                                disabled={!context.selection?.patient}
                                formSaved={() => { setRefresh(true); }}></FormView>
                        </Col>
                    </Row>
                </Content>
            </PageHeader>
            {/* <Prompt
                when={true} message="Είστε σίγουρος ότι θέλετε να φύγετε?" /> */}
            <SelectPatient onOK={okSelectPatient} onCancel={cancelselectPatient} open={selectPatientModalOpen}></SelectPatient>
            <SelectAdmission onOK={okSelectPatientAdmission} onCancel={cancelSelectPatientAdmission} open={selectPatientAdmissionModalOpen}></SelectAdmission>
        </Card>);
}